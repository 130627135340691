<template>
	
	<div class="row">
		<div class="col-lg-6">
			<div class="ibox">
				<div class="ibox-content">
					<div class="gradeX" v-for="etablissement in etablissements" :key="etablissement.id">
							{{ etablissement.titre }}            
							<button v-on:click="getProductsFromMappavini(etablissement)" class="btn btn-default">Voir les vins depuis Mappavini</button>
					</div>
				</div>
			</div>
			<div class="ibox">
				<div class="ibox-content">
					<div class="gradeX" v-for="produit_mappavini in produits_mappavini" :key="produit_mappavini.id">
							{{ produit_mappavini.libelle }}<br />
							Millesime : {{ produit_mappavini.millesime }}
							<button v-on:click="loadProductForm(produit_mappavini)" class="btn btn-primary">Ajouter</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="ibox">
				<div class="ibox-content">
					<h1>Produit</h1>
					<h2>Image</h2>
					<div v-if="produit.image">
						<img :src="produit.image.full_path" width="200" alt="">
					</div>
					<FileUploader :uploadurl="uploadImageUrl" @add-document="addDocument" />  
					<div class="form-group">
						<label for="">Titre :</label>
						<input v-model="v$.produit.titre.$model"  class="form-control" :class="{ 'form-error': v$.produit.titre.$errors.length }"/>
					</div>
					<div class="form-group">
						<label for="">Description :</label>
						<textarea v-model="produit.description" class="form-control"  />
					</div>
					
					<div class="form-group">
						<label for="">Millesime :</label>
						<input v-model="v$.produit.millesime.$model" class="form-control" :class="{ 'form-error': v$.produit.millesime.$errors.length }"/>
					</div>
					<div class="form-group">
						<label for="">Couleur :</label>
						<select name="" id=""  v-model="v$.produit.couleur.$model">
							<option value="blanc">Blanc</option>
							<option value="rouge">Rouge</option>
							<option value="rose">Rosé</option>
						</select>            
					</div>
					<div class="form-group">
						<label for="">Contenance :</label>
						<select name="" id="" v-model="v$.produit.contenance.$model"  class="form-control" :class="{ 'form-error': v$.produit.contenance.$errors.length }">
							<option value="50cl">50cl</option>
							<option value="75cl">75cl</option>
							<option value="1,5l">1,5l</option>
							<option value="5l">5l</option>
						</select>
					</div>

					<div class="form-group">
						<label for="">Stock:</label>
						<input v-model="v$.produit.stock.$model" class="form-control" :class="{ 'form-error': v$.produit.stock.$errors.length }" />
					</div>
					<div class="form-group">
						<label for="">Prix :</label>
						<input v-model="v$.produit.prix.$model" class="form-control" :class="{ 'form-error': v$.produit.prix.$errors.length }"/>
					</div>
					<div>
						<button v-on:click="saveData()" class="btn btn-primary">Valider</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { h } from 'vue'

import FileUploader from "@/components/FileUploader";
import { required, minLength, numeric } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {
	name: "ProduitImport",
	setup () {
		return { v$: useVuelidate() }
	},
	data() {
		return {
			produit: {},
			produits_mappavini: [],
			currentEtablissement: null
		};
	},
	validations() {
			return {
				produit: {
					titre: { 
							required,
							minLength: minLength(4)
					},
					prix: { 
							required,
							numeric
					},
					stock: { 
							required,
							numeric
					},
					millesime: { 
							required,
							numeric
					},
					couleur: { 
							required              
					},
					contenance: { 
							required
					}
				},
				
			}
	},
	created() {
		// fetch the data when the view is created and the data is
		// already being observed
		this.fetchData();
	},
	watch: {
		// call again the method if the route changes
		// $route: "fetchData",
	},
	methods: {
		fetchData() {      
			this.$store.dispatch('getEtablissements');
		},
		getProductsFromMappavini(etablissement) {
			this.currentEtablissement = etablissement
			axios
				.get(
					process.env.VUE_APP_API + "/producteur/produits_mappavini/" +
						etablissement.uuid,
					{}
				)
				.then((resp) => {                    
					this.produits_mappavini = resp.data;          
				})
				.catch((error) => {
					console.log("ERROR", error);
				});
		},
		loadProductForm(mappaviniProduct){
			this.v$.$reset()			
			this.produit.titre = mappaviniProduct.libelle
			this.produit.mappavini_id = mappaviniProduct.id
			this.produit.mappavini_cuvee_id = mappaviniProduct.cuvee_id
			this.produit.millesime = mappaviniProduct.millesime
			this.produit.etablissement = this.currentEtablissement
			// this.produit.mappavini_infos = JSON.stringify(mappaviniProduct)
			this.produit.mappavini_infos = mappaviniProduct
			axios
				.get(
					process.env.VUE_APP_API + "/producteur/cuvee_mappavini/" +
						mappaviniProduct.cuvee_id + "/" + mappaviniProduct.id
				)
				.then((resp) => {					
					this.produit.couleur = resp.data.vin.couleur.substr(4)
					this.produit.description = resp.data.vin.description_generale
					this.loadImageFromUrl(resp.data.cuvee.media_etiquette)
				})
				.catch((error) => {
					console.log("ERROR", error);
				});
		},
		loadImageFromUrl(path){			
			if(path){
				axios
					.post(
						process.env.VUE_APP_API + "/producteur/load_mappavini_image",
						{ image : path}
					)
					.then((resp) => {                    
						this.produit.image = resp.data            
					})
					.catch((error) => {
						console.log("ERROR", error);
					});
			}
		},
		saveData() {
			this.v$.$touch()
			if( !this.v$.$invalid){
				const loading = this.$loading({
						lock: true,
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					}); 
				axios
					.post(
						process.env.VUE_APP_API + "/producteur/produit/" +
							this.produit.uuid,
						this.produit
					)
					.then((resp) => {          
						loading.close();
						this.produit = resp.data;
						this.$router.push("/produit/" + this.produit.uuid);
						this.$notify({
							title: 'Produit enregistré',
							message: h('i', { style: 'color: teal' }, '')
						});
					})
					.catch((error) => {
						console.log("ERROR", error);
					});
			}
		},
		addDocument(doc){
				this.produit.image = doc
				this.saveData()
			},
			removeDocument(){
				this.produit.image = null;      
				this.saveData()
			},  
	},
	computed: {    
		etablissements () {
			return this.$store.state.etablissements.etablissements
		},
		uploadImageUrl() {
			return process.env.VUE_APP_API + '/producteur/document/upload/image'
		},
	},
	components: {
		FileUploader,
	},
};
</script>